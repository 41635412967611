import store from "@/store";
import {
  checkSizeLimit,
  checkUserMemoryLimit,
  acceptFormats
} from "@/util/user/checkLimit";

export function dragAndDropUploadFiles(dropZone) {
  const dropZoneEl = dropZone.querySelector(".drop-effect");
  function preventAndStop(e) {
    // e.stopPropagation();
    e.preventDefault();
  }
  function addClass(e) {
    preventAndStop(e);
    dropZone.classList.add("drop-zone");
  }
  function removeClass(e) {
    preventAndStop(e);
    dropZone.classList.remove("drop-zone");
  }
  // dropZone.addEventListener("drag", preventAndStop);
  // dropZone.addEventListener("dragstart", preventAndStop);
  dropZone.addEventListener("dragover", addClass);
  dropZone.addEventListener("dragenter", addClass);
  dropZoneEl.addEventListener("dragend", removeClass);
  dropZoneEl.addEventListener("dragleave", removeClass);
  dropZoneEl.addEventListener("drop", removeClass);

  // Get file data on drop
  dropZoneEl.addEventListener("drop", function(e) {
    preventAndStop(e);
    const files = e.dataTransfer.files;
    let filesSize = 0;
    for (let i = 0; i < files.length; i++) {
      filesSize += files[i].size;
    }
    if (!checkUserMemoryLimit({ size: filesSize })) return;
    files.forEach(f => {
      try {
        if (!acceptFormats.includes(f.type) || !f.type) {
          store.dispatch("workSpace/showPopup", {
            type: "errorPopup",
            data: "accepted_files"
          });
          return;
        }
        if (!checkSizeLimit(f)) return;
        store.dispatch("customContent/uploadCustomItem", {
          type: "myImages",
          file: f
        });
      } catch (error) {
        console.log(error);
      }
    });
  });
}

export function dragAndDropUpdateRichImage(dropZone) {
  const dropZoneEl = dropZone.querySelector(".drop-effect");
  if (!dropZoneEl) return;
  function preventAndStop(e) {
    // e.stopPropagation();
    e.preventDefault();
  }
  function addClass(e) {
    preventAndStop(e);
    dropZone.classList.add("drop-zone");
  }
  function removeClass(e) {
    preventAndStop(e);
    dropZone.classList.remove("drop-zone");
  }
  // dropZone.addEventListener("drag", preventAndStop);
  // dropZone.addEventListener("dragstart", preventAndStop);
  dropZone.addEventListener("dragover", addClass);
  dropZone.addEventListener("dragenter", addClass);
  dropZoneEl.addEventListener("dragend", removeClass);
  dropZoneEl.addEventListener("dragleave", removeClass);
  dropZoneEl.addEventListener("drop", removeClass);

  // Get file data on drop
  dropZoneEl.addEventListener("drop", function(e) {
    const file = e.dataTransfer.files[0];
    if (file.type !== "image/svg+xml") return;
    store.dispatch("updateDropedFile", file);
  });
}
