<template>
  <div
    class="cloud-wraper"
    :style="{ 'max-height': wraperHeight }"
    ref="dropZone"
  >
    <div @click.stop class="drop-upload">
      <div
        class="close-tab"
        @click="$store.commit('workSpace/updateVisibleCustomPanel', false)"
        v-show="device === 'mobile'"
      >
        <span>&times;</span>
      </div>
      <img alt="img" class="drop-upload-image" src="@/assets/img/upload.svg" />
      <div class="drop-upload-text">
        drop image to upload
      </div>
    </div>
    <div class="drop-effect"></div>
    <div class="top-wraper">
      <div class="top-info">
        <div title="3123123123" class="limits-board">
          <b>{{ myInteractions.length }}</b>
          interactions
        </div>
        <div class="limits-board">
          <b>{{ sizeInMBs(customContentSize) }}Mb</b>/{{ memoryLimit }}Mb
        </div>
      </div>
      <div
        class="contributions-container"
        style="cursor: pointer;"
        @click="
          $store.dispatch('workSpace/showPopup', { type: 'dashboardPopup' })
        "
      >
        <!-- statistic -->
        <div class="responds-info">
          {{ newRespondsLength }}/{{ allRespondsLength }}
        </div>
        <div class="respond-info-title">
          responds
        </div>
      </div>
    </div>
    <!-- search -->
    <div class="search-input-wraper">
      <img alt="img" class="search-img" src="@/assets/img/search.svg" />
      <input
        class="search-input"
        type="text"
        ref="searchInput"
        v-model.trim="searchInput"
      />
      <div v-show="searchInput" @click="searchInput = ''" class="clear-input">
        &times;
      </div>
    </div>
    <div class="content" :style="{ height: contentContainerHeight }">
      <preloader class="main-preloaeder" :visible="isLoadingContent" />
      <!-- interactions -->
      <div class="poster-wraper">
        <div
          class="posters-title-wraper"
          @click.stop="accardion.interactions = !accardion.interactions"
        >
          <div class="poster-title">
            my interactions
            {{ myInteractionsMod.length }}
          </div>
          <img
            alt="img"
            src="@/assets/img/select_arrow.svg"
            :class="{ hide_content: accardion.interactions }"
          />
        </div>
        <div
          class="posters-container"
          :style="{
            maxHeight: getContentHeight(myInteractionsMod, 2, 'interactions')
          }"
        >
          <div class="add-new interaction-container" v-show="!searchInput">
            <div
              class="item-img-container interaction-item frame"
              @click="showCreatePopup('my interaction')"
            >
              <img alt="img" class="icons" src="@/assets/img/add_btn.svg" />
            </div>
            <span class="span-item">create</span>
          </div>
          <div
            class="add-new interaction-container"
            :style="{ marginRight: idx % 2 ? null : '0px' }"
            v-for="(i, idx) of myInteractionsMod"
            :key="i.id"
            v-double-click="{
              cb: addToPreveiw,
              args: [i]
            }"
          >
            <div
              class="add-new interaction-item frame"
              :class="{ active: activeInteractionId === i.id }"
            >
              <div
                class="staus-mark"
                :class="{ 'status-active': i.active }"
              ></div>
              <div
                class="img-container"
                v-show="idx <= 3"
                v-for="(img, idx) of i.img"
                :key="idx"
              >
                <img alt="img" v-if="img" :src="img" />
                <div v-else></div>
              </div>
              <el-dropdown
                @click.native.stop
                trigger="click"
                class="content-menu"
              >
                <div>&hellip;</div>
                <el-dropdown-menu
                  class="content-menu-container"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    v-for="li in ['rename', 'duplicate', 'delete']"
                    :key="li"
                    class="content-menu-item"
                    @click.native="showPopup(i, li, 'myInteractions')"
                  >
                    {{ li }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span class="span-item">{{ i.name }}</span>
          </div>
        </div>
      </div>
      <!-- posters -->
      <div class="poster-wraper">
        <div
          class="posters-title-wraper"
          @click.stop="accardion.posters = !accardion.posters"
        >
          <div class="poster-title">
            my posters:
            {{ postersMod.length }}
          </div>
          <img
            alt="img"
            src="@/assets/img/select_arrow.svg"
            :class="{ hide_content: accardion.posters }"
          />
        </div>
        <div
          class="posters-container"
          :style="{
            maxHeight: getContentHeight(postersMod, 4, 'posters')
          }"
        >
          <div class="add-new " v-show="!searchInput">
            <div
              class="item-img-container frame"
              @click="showCreatePopup('poster')"
            >
              <img alt="img" class="icons" src="@/assets/img/add_btn.svg" />
            </div>
            <span class="span-item">create</span>
          </div>
          <div
            class="posters-item"
            v-for="p of postersMod"
            :key="p.id"
            v-double-click="{
              cb: addToCanvas,
              args: [p]
            }"
          >
            <div class="item-img-container poster-item">
              <img
                alt="img"
                @dragstart="handleDragStart"
                @dragend="handleDragEnd"
                v-drag-item="p"
                class="poster-img frame"
                :class="{ active: activePosterId === p.id }"
                :src="p.image"
              />
              <el-dropdown
                @click.native.stop
                trigger="click"
                class="content-menu"
              >
                <div>&hellip;</div>
                <el-dropdown-menu
                  class="content-menu-container"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    v-for="li in ['rename', 'duplicate', 'delete']"
                    :key="li"
                    class="content-menu-item"
                    @click.native="showPopup(p, li, 'posters')"
                  >
                    {{ li }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span
              class="span-item"
              :class="{ 'active-poster': p === activePoster }"
              >{{ p.name }}
            </span>
          </div>
        </div>
      </div>
      <!-- images -->
      <div class="poster-wraper">
        <div
          class="posters-title-wraper"
          @click.stop="accardion.images = !accardion.images"
        >
          <div class="poster-title">
            my images:
            {{ myImagesMod.length }}
          </div>
          <img
            alt="img"
            src="@/assets/img/select_arrow.svg"
            :class="{ hide_content: accardion.images }"
          />
        </div>
        <div
          class="posters-container"
          :style="{
            maxHeight: getContentHeight(myImagesMod, 4, 'images')
          }"
        >
          <div class="add-new" v-show="!searchInput">
            <label class="item-img-container frame upload-image">
              <input
                type="file"
                :accept="imgTypes"
                hidden
                @input="uploadMyImages"
              />
              <img alt="img" class="icons" src="@/assets/img/upload.svg" />
            </label>
            <span class="span-item">upload</span>
          </div>
          <div class="add-new" v-show="fileUploading === 'myImages'">
            <div class="item-img-container frame upload-image">
              <preloader :visible="true" class="preloader" />
            </div>
            <span class="span-item">uploading...</span>
          </div>
          <div
            class="posters-item"
            v-for="p of myImagesMod"
            :key="p.id"
            v-double-click="{
              cb: addToCanvasImage,
              args: [p]
            }"
          >
            <div class="item-img-container upload-image">
              <img
                alt="img"
                @dragstart="handleDragStart"
                @dragend="handleDragEnd"
                v-drag-item="p"
                class="poster-img frame upload-img"
                :src="p.content"
              />
              <el-dropdown
                @click.native.stop
                trigger="click"
                class="content-menu"
              >
                <div>&hellip;</div>
                <el-dropdown-menu
                  class="content-menu-container"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    v-for="li in ['rename', 'delete']"
                    :key="li"
                    class="content-menu-item"
                    @click.native="showPopup(p, li, 'myImages')"
                  >
                    {{ li }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span class="span-item">{{ p.name }}</span>
          </div>
        </div>
      </div>
      <!-- fonts -->
      <div class="poster-wraper">
        <div
          class="posters-title-wraper"
          @click.stop="accardion.fonts = !accardion.fonts"
        >
          <div class="poster-title">my fonts: {{ myFontsMod.length }}</div>
          <img
            alt="img"
            src="@/assets/img/select_arrow.svg"
            :class="{ hide_content: accardion.fonts }"
          />
        </div>
        <div
          class="posters-container"
          :style="{ maxHeight: getContentHeight(myFontsMod, 2, 'fonts') }"
        >
          <div class="add-new" v-show="!searchInput">
            <label class="item-img-container frame upload-font">
              <input
                type="file"
                hidden
                @input="uploadMyFonts"
                ref="uploadFont"
              />
              <img alt="img" class="icons" src="@/assets/img/upload.svg" />
            </label>
            <span class="span-item"> upload </span>
          </div>
          <div class="add-new" v-show="fileUploading === 'myFonts'">
            <div class="item-img-container frame upload-image">
              <preloader :visible="true" class="preloader" />
            </div>
            <span class="span-item">uploading...</span>
          </div>
          <div
            class="posters-item"
            v-for="p of myFontsMod"
            :key="p.id"
            v-double-click="{
              cb: addToCanvasFont,
              args: [p]
            }"
          >
            <div class="item-img-container frame upload-font">
              <div class="big_a">a</div>
              <el-dropdown
                @click.native.stop
                trigger="click"
                class="content-menu"
              >
                <div>&hellip;</div>
                <el-dropdown-menu
                  class="content-menu-container"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    v-for="li in ['rename', 'delete']"
                    :key="li"
                    class="content-menu-item"
                    @click.native="showPopup(p, li, 'myFonts')"
                  >
                    {{ li }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span class="span-item">{{ p.name }}</span>
          </div>
        </div>
      </div>
      <!-- attachments -->
      <div class="poster-wraper">
        <div
          class="posters-title-wraper"
          @click.stop="accardion.myAttachments = !accardion.myAttachments"
        >
          <div class="poster-title">
            my attachments:
            {{ myAttachmentsMod.length }}
          </div>
          <img
            alt="img"
            src="@/assets/img/select_arrow.svg"
            :class="{ hide_content: accardion.myAttachments }"
          />
        </div>
        <div
          class="posters-container"
          :style="{
            maxHeight: getContentHeight(myAttachmentsMod, 4, 'myAttachments')
          }"
        >
          <div class="add-new" v-show="!searchInput">
            <label class="item-img-container frame upload-font">
              <input
                type="file"
                hidden
                @input="uploadMyAttachments"
                ref="uploadFont"
              />
              <img alt="img" class="icons" src="@/assets/img/upload.svg" />
            </label>
            <span class="span-item"> upload </span>
          </div>
          <div class="add-new" v-show="fileUploading === 'myAttachments'">
            <div class="item-img-container frame upload-image">
              <preloader :visible="true" class="preloader" />
            </div>
            <span class="span-item">uploading...</span>
          </div>
          <div class="posters-item" v-for="p of myAttachmentsMod" :key="p.id">
            <div class="item-img-container frame upload-font">
              <div class="big_a">
                <img
                  alt="img"
                  class="poster-img"
                  :src="p.content"
                  @error="onErrorShowFileImg"
                />
              </div>
              <el-dropdown
                @click.native.stop
                trigger="click"
                class="content-menu"
              >
                <div>&hellip;</div>
                <el-dropdown-menu
                  class="content-menu-container"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    v-for="li in ['rename', 'delete']"
                    :key="li"
                    class="content-menu-item"
                    @click.native="showPopup(p, li, 'myAttachments')"
                  >
                    {{ li }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span class="span-item">{{ p.name }}</span>
          </div>
        </div>
      </div>
      <!-- user attachments -->
      <div class="poster-wraper" v-if="userAttachments.length">
        <div
          class="posters-title-wraper"
          @click.stop="accardion.userAttachments = !accardion.userAttachments"
        >
          <div class="poster-title">
            user attachments:
            {{ userAttachmentsMod.length }}
          </div>
          <img
            alt="img"
            src="@/assets/img/select_arrow.svg"
            :class="{ hide_content: accardion.userAttachments }"
          />
        </div>
        <div
          class="posters-container"
          :style="{
            maxHeight: getContentHeight(
              userAttachmentsMod,
              4,
              'userAttachments'
            )
          }"
        >
          <div
            class="posters-item"
            v-for="p of userAttachmentsMod"
            @click="onClickUserAttachment($event, p)"
            :key="p.id"
          >
            <div class="item-img-container frame upload-font">
              <img
                alt="img"
                class="poster-img"
                :src="p.content"
                @error="onErrorShowFileImg"
              />
              <el-dropdown
                @click.native.stop
                trigger="click"
                class="content-menu"
              >
                <div>&hellip;</div>
                <el-dropdown-menu
                  class="content-menu-container"
                  slot="dropdown"
                >
                  <el-dropdown-item
                    v-for="li in ['rename', 'delete']"
                    :key="li"
                    class="content-menu-item"
                    @click.native="showPopup(p, li, 'userAttachments')"
                  >
                    {{ li }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span class="span-item">{{ p.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ? services
import LogsService from "@/services/LogsService";
// ? helpers
import { mapGetters } from "vuex";
import preloader from "@/components/preloaderMenu";
import {
  checkSizeLimit,
  checkUserMemoryLimit,
  acceptFormats
} from "@/util/user/checkLimit";
import AddToCanvasItem from "@/util/canvas/addToCanvas";
import { addWidget } from "@/util/survey/widgets";
import { dragAndDropUploadFiles } from "@/helpers/dragElems";
import Survey from "@/util/survey/survey";
// import { getWSApolloClient } from "@/plugins/vue-apollo";
import fileTypes from "@/store/mocks/uploadAllowedFormats";
import dragAndDropMixin from "@/mixins/dragAndDropContent";
import {getIndexFromPath} from "@/util/content-path";

export default {
  name: "cloud",
  mixins: [dragAndDropMixin],
  components: {
    preloader
  },
  data() {
    return {
      responds: 0,
      searchInput: "",
      visiblePopup: false,
      popupData: {},
      step: null,
      itemType: "",
      itemName: "",
      itemId: "",
      imgTypes: acceptFormats,
      // apollo: null,
      clickCount: 0,
      singleClickTimer: null,
      wasLogedMore90: false,
      accardion: {
        interactions: false,
        posters: false,
        images: false,
        fonts: false,
        myAttachments: false,
        userAttachments: false
      }
    };
  },
  created() {
    this.$store.dispatch("customContent/getCustomContent").then(async () => {
      if (this.$route.params.poster && this.$route.query.c) this.addToCanvasPosterFromURL();
      if (this.$route.params.interaction) this.addToPreviewFromURL();
      await this.$store.dispatch("workSpace/doActionAfterLogin");
      // ? register new apollo provider for ws connection params with current user
      // this.apollo = getWSApolloClient();
      this.subscribeResponds();
    });
    localStorage.removeItem("offlinePoster");
  },
  computed: {
    ...mapGetters({
      isLoadingContent: "customContent/isLoadingContent",
      isLoggedIn: "auth/isLoggedIn",
      customContentSize: "customContent/customContentSize",
      myInteractions: "customContent/myInteractions",
      newRespondsLength: "customContent/newRespondsLength",
      allRespondsLength: "customContent/allRespondsLength",
      posters: "customContent/posters",
      myImages: "customContent/myImages",
      myFonts: "customContent/myFonts",
      myAttachments: "customContent/myAttachments",
      userAttachments: "customContent/userAttachments",
      canvas: "workSpace/canvas",
      activePoster: "workSpace/activePoster",
      editContentMode: "workSpace/editContentMode",
      editData: "workSpace/editData",
      isEmptyCanvas: "workSpace/isEmptyCanvas",
      isSavedPoster: "workSpace/isSavedPoster",
      windowHeight: "workSpace/windowHeight",
      device: "workSpace/device",
      interactionMode: "workSpace/interactionMode",
      survey: "workSpace/survey",
      activeMyInteraction: "workSpace/activeMyInteraction",
      cookiesBannerHeight: "workSpace/cookiesBannerHeight",
      limits: "customContent/limits",
      fileUploading: "customContent/fileUploading",
      pages: "feed/pages"
    }),
    wraperHeight() {
      if (this.device === "mobile") {
        return `${(this.windowHeight - this.cookiesBannerHeight).toFixed()}px`;
      }
      return `${(
        this.windowHeight -
        123 -
        this.cookiesBannerHeight
      ).toFixed()}px`;
    },
    contentContainerHeight() {
      if (this.device === "mobile")
        return `${(
          (this.windowHeight / 100) * 80 -
          150 -
          this.cookiesBannerHeight
        ).toFixed()}px`;
      return `${(
        this.windowHeight -
        230 -
        this.cookiesBannerHeight
      ).toFixed()}px`;
    },
    activePosterId() {
      return !this.interactionMode && this.activePoster?.id;
    },
    activeInteractionId() {
      return this.interactionMode && this.activeMyInteraction?.id;
    },
    postersMod() {
      const inputValue = this.searchInput.toLowerCase();
      if (!this.searchInput) return this.posters;
      return this.posters.filter(p => {
        if (p.name.toLowerCase().includes(inputValue)) return true;
        return false;
      });
    },
    myInteractionsMod() {
      const inputValue = this.searchInput.toLowerCase();
      if (!this.searchInput) return this.myInteractions;
      return this.myInteractions.filter(i => {
        if (i.name.toLowerCase().includes(inputValue)) return true;
        return false;
      });
    },
    memoryLimit() {
      return Math.round(this.sizeInMBs(this.limits.memory));
    },
    myImagesMod() {
      const inputValue = this.searchInput.toLowerCase();
      if (!this.searchInput) return this.myImages;
      return this.myImages.filter(f => {
        if (f.name.toLowerCase().includes(inputValue)) return true;
        return false;
      });
    },
    myFontsMod() {
      const inputValue = this.searchInput.toLowerCase();
      if (!this.searchInput) return this.myFonts;
      return this.myFonts.filter(f => {
        if (f.name.toLowerCase().includes(inputValue)) return true;
        return false;
      });
    },
    myAttachmentsMod() {
      const inputValue = this.searchInput.toLowerCase();
      if (!this.searchInput) return this.myAttachments;
      return this.myAttachments.filter(f => {
        if (f.name.toLowerCase().includes(inputValue)) return true;
        return false;
      });
    },
    userAttachmentsMod() {
      const inputValue = this.searchInput.toLowerCase();
      if (!this.searchInput) return this.userAttachments;
      return this.userAttachments.filter(f => {
        if (f.name.toLowerCase().includes(inputValue)) return true;
        return false;
      });
    }
  },
  mounted() {
    dragAndDropUploadFiles(this.$refs.dropZone);
    if (this.$route.params.poster !== "unsaved_poster") return;
    localStorage.removeItem("offlinePoster");
  },
  methods: {
    subscribeResponds() {
      this.$apollo
        .subscribe({
          query: require("@/services/graphql/myInteractions/sub/subNewRespondsLength.gql")
        })
        .subscribe(({ data }) => {
          const respondsLength = data?.respond_aggregate?.aggregate?.count;
          this.$store.commit(
            "customContent/updateNewRespondsLength",
            respondsLength
          );
        });
      this.$apollo
        .subscribe({
          query: require("@/services/graphql/myInteractions/sub/subAllRespondsLength.gql")
        })
        .subscribe(({ data }) => {
          const respondsLength = data?.respond_aggregate?.aggregate?.count;
          this.$store.commit(
            "customContent/updateAllRespondsLength",
            respondsLength
          );
        });
      // ? user attachments
      this.$apollo
        .subscribe({
          query: require("@/services/graphql/myInteractions/sub/subUserAttachments.gql")
        })
        .subscribe(({ data: { user_attachments } }) => {
          this.$store.commit(
            "customContent/updateUserAttachments",
            user_attachments
          );
        });
    },
    onErrorShowFileImg({ target }) {
      target.src = require("@/assets/img/file.svg");
    },
    async addToPreviewFromURL() {
      const interactionName = this.$route.params.interaction;
      const idPath = this.$route.query.i;
      const item = this.myInteractions.find(
        i =>
          i.name.replace(/( |_)/g, "") ===
            interactionName.replace(/( |_)/g, "") && i.id.startsWith(idPath)
      );
      if (!item) this.$router.push(`/unsaved_poster`);
      else {
        if (!this.pages?.length) this.$store.dispatch("feed/getPages");
        await Survey.myInteraction(item);
      }
    },
    async addToCanvasPosterFromURL() {
      if (this.canvas?._objects?.length) return;
      const pathName = this.$route.params.poster
      if (pathName === "unsaved_poster") return;
      const posterIndex = getIndexFromPath(pathName);
      const item = this.posters.find(i => i.index === posterIndex);
      if (!item) this.$router.replace("unsaved_poster");
      else await AddToCanvasItem.template(item, "poster");
    },
    // async addToCanvasPosterFromURL() {
    //   if (this.canvas?._objects?.length) return;
    //   const posterName = this.$route.params.poster;
    //   let idPath = this.$route.query.p;
    //   if (posterName === "unsaved_poster") return;
    //   const item = this.posters.find(
    //     i =>
    //       i.name.replace(/( |_)/g, "") === posterName.replace(/( |_)/g, "") &&
    //       i.id.startsWith(idPath)
    //   );
    //   if (!item) this.$router.replace("unsaved_poster");
    //   else await AddToCanvasItem.template(item, "poster");
    // },
    // ? add to canvas or interaction preview
    addToPreveiw(item) {
      if (this.editContentMode) return;
      Survey.myInteraction(item);
    },
    addToCanvasImage(item) {
      if (this.interactionMode)
        addWidget("image", this.survey, {
          configs: {
            imageSrc: item.content,
            actionType: "no action",
            linkTarget: "open in existing tab",
            pageTarget: 1,
            url: "",
            socialNetwork: "none",
            allowedFileType: [...fileTypes],
            sizeLimit: 1,
            isSvg: false
          }
        });
      else AddToCanvasItem.myImage(item);
    },
    async addToCanvas(poster) {
      this.canvas.fire("cropper:cancel");
      if (this.editContentMode) return;
      if (this.notSavedPoster) {
        await this.$store.dispatch("workSpace/showPopup", {
          type: "saveChangesPopup",
          data: { poster, type: "poster" }
        });
        this.$store.commit("workSpace/updateVisibleCustomPanel", false);
      } else await AddToCanvasItem.template(poster, "poster");
    },
    addToCanvasFont(item) {
      if (this.interactionMode) {
        const fontFamily = item.name.replace(/\.(t|o)tf/g, "");
        addWidget("text", this.survey, {
          fontFamily,
          text: fontFamily
        });
        this.survey.renderAll();
      } else AddToCanvasItem.font(item, true);
    },
    onClickUserAttachment(item) {
      if (!checkUserMemoryLimit({ size: 0 })) return;
      const a = document.createElement("a");
      a.href = item.content;
      a.target = "_blank";
      a.click();
    },
    showCreatePopup(itemType) {
      this.$store.dispatch("workSpace/showPopup", {
        type: "createCustomItemPopup",
        data: { itemType }
      });
      if (this.device === "mobile")
        this.$store.commit("workSpace/updateVisibleCustomPanel", false);
    },
    getContentHeight(list, rowLenght, type) {
      const rowHeight = {
        interactions: 96,
        posters: 125,
        images: 96,
        fonts: 96,
        myAttachments: 96,
        userAttachments: 96
      };
      const oneRowHeight = rowHeight[type];
      const i = Math.ceil(list?.length || 1 / rowLenght) * oneRowHeight;
      return (this.accardion[type] ? 0 : i) + "px";
    },
    uploadMyImages(e) {
      try {
        const fileLength = e.target.files.length - 1;
        const file = e.target.files[fileLength];
        if (!checkSizeLimit(file)) return;
        if (!checkUserMemoryLimit(file)) return;
        this.$store.dispatch("customContent/uploadCustomItem", {
          type: "myImages",
          file
        });
      } catch (error) {
        console.log(error);
      }
    },
    uploadMyFonts(e) {
      try {
        const fileLength = e.target.files.length - 1;
        const file = e.target.files[fileLength];
        if (!checkSizeLimit(file)) return;
        if (!checkUserMemoryLimit(file)) return;
        if (!file.name.endsWith(".ttf") && !file.name.endsWith(".otf")) {
          this.$store.dispatch("workSpace/showPopup", {
            type: "errorPopup",
            data: "accepted_fonts"
          });
          return;
        }
        this.$store.dispatch("customContent/uploadCustomItem", {
          type: "myFonts",
          file
        });
      } catch (error) {
        console.log(error);
      }
    },
    async uploadMyAttachments(e) {
      try {
        const fileLength = e.target.files.length - 1;
        const file = e.target.files[fileLength];
        if (!checkUserMemoryLimit(file)) return;
        this.$store.dispatch("customContent/uploadCustomItem", {
          type: "myAttachments",
          file
        });
      } catch (error) {
        console.log(error);
      }
    },
    sizeInMBs(size) {
      return (size / 1048576).toFixed(2);
    },
    serializeMyFonts(fontsList) {
      return fontsList.map(f => ({
        name: f.name.replace(".ttf", "").replace(".otf", ""),
        url: f.content,
        isCustom: true
      }));
    },
    showPopup(item, step, itemType) {
      const type =
        step === "delete" ? "deleteCustomItemPopup" : "customItemPopup";
      this.$store.dispatch("workSpace/showPopup", {
        data: { step, item, itemType },
        type
      });
    }
  },
  watch: {
    myAllSizeInMB(newSize) {
      // ? log 90% of limit memory
      if (!this.memoryLimit || !newSize) return;
      const isMore90 = (100 / this.memoryLimit) * newSize > 90;
      if (isMore90 && !this.wasLogedMore90) {
        this.wasLogedMore90 = true;
        LogsService.createLog({
          action: "Reached 90% of data limit",
          data: {}
        });
      }
    }
  }
};
</script>

<style scoped>
.cloud-wraper {
  width: 100%;
  max-width: 325px;
  position: relative;
  border-radius: 15px;
}
.poster-wraper {
  height: fit-content;
  width: calc(100% - 15px);
  margin-left: 15px;
}
.content {
  width: calc(100% - 5px);
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: start;
}
.span-item {
  margin-top: 2px;
  font-size: 8px;
  max-width: 67px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.poster-img {
  max-height: 100%;
  max-width: 65px;
}
.item-img {
  height: 16px;
}
.preloader >>> .single6 {
  width: 30px;
  height: 30px;
}
.contributions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.my-contributions {
  font-weight: 600;
  font-size: 10px;
  margin-top: 3px;
  cursor: pointer;
}
/* top */
.top-wraper {
  display: flex;
  height: 65px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding: 0 21px 0 13px;
}
.contribute {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 142px;
  height: 30px;
  background: #ffe000;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.input-wraper {
  position: relative;
}
.item-image-container {
  display: flex;
  flex-wrap: wrap;
}
.posters-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  transition: max-height 0.2s;
  overflow: hidden;
}
.add-new,
.posters-item {
  margin: 10px 10px 0 0;
  width: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.content-menu {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 5px;
  line-height: 0;
  height: 10px;
  overflow: hidden;
  font-size: 42px;
  background: #fff;
  border-radius: 7px;
  transition: opacity 0.2s;
  z-index: -1;
}
.content-menu-item {
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  padding: 0 10px;
}
.content-menu-container .content-menu-item:hover {
  background-color: #f8f8f5;
  color: black;
}
.content-menu div {
  position: relative;
  top: -7px;
}
.content-menu-container {
  padding: 5px 0;
}

.item-img-container:hover .content-menu,
.interaction-item:hover .content-menu {
  opacity: 1;
  z-index: 5;
}

.item-img-container {
  max-width: 65px;
  width: 100%;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.frame {
  box-sizing: border-box;
  border: 1px solid #bcbccb;
  border-radius: 7px;
  overflow: hidden;
}
.input-left-panel-wraper {
  padding-top: 0;
  padding-bottom: 10px;
}
.search-wraper {
  margin-top: 0;
}
.posters-title-wraper {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
}
.posters-title-wraper img {
  margin-right: 5px;
  transition: transform 0.2s;
}
.hide_content {
  transform: rotate(-90deg);
}
.main-preloaeder {
  z-index: 10;
}
.upload-image {
  max-height: 65px;
}
.interaction-container {
  width: 137px;
  box-sizing: border-box;
  max-width: none;
}
.interaction-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 75px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  max-width: 140px;
  flex-direction: row;
}
.interaction-item .img-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  width: 30px;
  height: 52px;
  margin: 1.5px;
}
.staus-mark {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 7px;
  right: 7px;
}
.status-active {
  background: #0c0;
}
.img-container img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.upload-font {
  max-height: 50px;
}
.limits-board {
  font-size: 14px;
  line-height: 20px;
}
.limits-board b {
  font-weight: 600;
}
.no-content {
  margin: 20px;
  font-size: 11px;
  text-align: center;
}
/* drop zone */
.drop-zone .drop-effect {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 3px solid #ffe000;
  z-index: 10;
  background: #ffe00022;
}
.drop-zone .drop-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 196px;
  height: 68px;
  z-index: 9;
  background: #ffe000;
}
.drop-upload-image,
.drop-upload-text {
  display: none;
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
}
.drop-zone .drop-upload-image,
.drop-zone .drop-upload-text {
  display: flex;
}
.big_a {
  display: flex;
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
}
.frame.active {
  border: 1px solid #ffe000;
  border-radius: 7px;
  box-sizing: border-box;
}
.close-tab {
  position: relative;
  border-bottom: none;
  height: 20px;
}
.close-tab span {
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  top: 4px;
  right: 10px;
  cursor: pointer;
}
.responds-info {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.respond-info-title {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
}
.search-input-wraper {
  padding-top: 0;
}
.search-img,
.clear-input {
  margin-top: 0;
  top: 0;
}

@media screen and (max-width: 990px) {
  .cloud-wraper {
    width: 100%;
    max-width: calc(100vw - 5px);
  }
  .interaction-container:hover .content-menu,
  .posters-item:hover .content-menu {
    opacity: -1;
    z-index: -5;
  }
}
</style>
